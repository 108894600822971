import React from "react";
import { LoadingLine } from "@thecb/components";
import { BLACK_SQUEEZE, GRECIAN_GREY } from "../../constants/colors";

const ContentWithLoader = ({
  content,
  isLoading,
  displayContent = true,
  minWidth = "70",
  maxWidth = "218",
  height = "18px",
  margin = "0px"
}) => {
  if (isLoading) {
    return (
      <LoadingLine
        minWidth={minWidth}
        maxWidth={maxWidth}
        height={height}
        margin={margin}
        backgroundColor={GRECIAN_GREY}
        foregroundColor={BLACK_SQUEEZE}
        style={{ borderRadius: "0.125rem" }}
      />
    );
  }
  return !content || !displayContent ? null : <>{content}</>;
};

export default ContentWithLoader;
