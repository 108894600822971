import React, { useState } from "react";
import { Box, Cluster } from "../../../atoms/layouts";
import ButtonWithAction from "../../../atoms/button-with-action";
import AutopayModalModule from "./AutopayModalModule";
import { GHOST_GREY } from "../../../../constants/colors";
import AmountModule from "./AmountModule";
import { noop } from "../../../../util/general";

const PaymentDetailsActions = ({
  isMobile,
  obligations,
  config,
  actions,
  autoPayEnabled,
  autoPayAvailable,
  handleAutopayAction,
  deactivatePaymentSchedule,
  navigateToSettings,
  autoPaySchedule,
  paymentPlanSchedule,
  isPaymentPlan,
  nextAutopayDate,
  obligationAssocID,
  dueDate,
  description,
  subDescription,
  allowedPaymentInstruments,
  disableActions = false,
  cartEnabled,
  cartConfig,
  isInCart
}) => {
  const planType = isPaymentPlan ? "Payment Plan" : "Autopay";
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, toggleModal] = useState(false);
  const { obligationSlug } = config;
  const {
    createPaymentFromProfile,
    configureMultiCart,
    addToMultiCart,
    setDetailedObligation,
    navigateToDetailedObligation,
    openCartSlider
  } = actions;
  const detailsSlug =
    config.type === "ACCOUNT"
      ? `/profile/accounts/details/${obligationSlug}`
      : `/profile/properties/details/${obligationSlug}`;
  const handleClick = obligations => {
    if (isInCart) {
      openCartSlider();
      return;
    }
    if (cartEnabled && cartConfig) {
      const { subClientSlug, addToCartConfig } = cartConfig;
      configureMultiCart({ cartId: subClientSlug, config: cartConfig });
      addToMultiCart({
        cartId: subClientSlug,
        items: addToCartConfig.items,
        itemType: addToCartConfig.itemType
      });
    } else {
      setIsLoading(true);
      createPaymentFromProfile(obligations, config);
    }
  };
  const handleDetailsClick = () => {
    setDetailedObligation(obligations, config, obligationAssocID);
    navigateToDetailedObligation(detailsSlug);
  };
  const variant = cartEnabled
    ? isInCart
      ? "greenPrimary"
      : "secondary"
    : isMobile
    ? "smallSecondary"
    : "secondary";
  const text = cartEnabled ? (isInCart ? "In Cart" : "Add to Cart") : "Pay Now";
  return (
    <Box
      padding={isMobile ? "0" : "16px 0 0"}
      minWidth="100%"
      borderSize="1px"
      borderColor={GHOST_GREY}
      borderWidthOverride="1px 0 0 0"
      key={`${obligations[0].id}-actions`}
    >
      {isMobile && (
        <Box
          padding="16px 24px"
          borderSize="1px"
          borderColor={GHOST_GREY}
          borderWidthOverride="0 0 1px 0"
        >
          <Cluster justify="space-between" align="center" nowrap>
            <AmountModule
              totalAmountDue={obligations.reduce(
                (acc, curr) => acc + curr.amountDue,
                0
              )}
              autoPayEnabled={autoPayEnabled}
              isMobile={isMobile}
              deactivatePaymentSchedule={deactivatePaymentSchedule}
              navigateToSettings={navigateToSettings}
              autoPaySchedule={autoPaySchedule}
              paymentPlanSchedule={paymentPlanSchedule}
              isPaymentPlan={isPaymentPlan}
              nextAutopayDate={nextAutopayDate}
              description={description}
              subDescription={subDescription}
              allowedPaymentInstruments={allowedPaymentInstruments}
              isInCustomerManagement={disableActions}
            />
          </Cluster>
        </Box>
      )}
      <Box padding={isMobile ? "16px" : "0"}>
        <Cluster
          justify={isMobile ? "center" : "flex-end"}
          align="center"
          childGap="0"
        >
          <Box
            padding={isMobile ? "0 8px 0 0" : "0"}
            extraStyles={isMobile && `flex-grow: 1;`}
          >
            <ButtonWithAction
              variant="tertiary"
              text={
                config.type === "ACCOUNT"
                  ? "Account Details"
                  : "Property Details"
              }
              action={handleDetailsClick}
              dataQa="Account Details"
              extraStyles={isMobile && `flex-grow: 1; width: 100%;`}
            />
          </Box>
          <Box
            padding={isMobile ? "0 8px 0 0" : "0"}
            extraStyles={isMobile && `flex-grow: 1;`}
          >
            {autoPayAvailable && !autoPayEnabled ? (
              <ButtonWithAction
                variant="tertiary"
                text={`Set Up ${planType}`}
                action={() => {
                  setDetailedObligation(obligations, config, obligationAssocID);
                  handleAutopayAction();
                }}
                dataQa="Set Up Autopay"
                extraStyles={isMobile && `flex-grow: 1; width: 100%;`}
                disabled={disableActions}
              />
            ) : (
              <AutopayModalModule
                autoPayActive={autoPayEnabled}
                autoPaySchedule={autoPaySchedule}
                toggleModal={toggleModal}
                modalOpen={modalOpen}
                navigateToSettings={navigateToSettings}
                deactivatePaymentSchedule={deactivatePaymentSchedule}
                controlType="tertiary"
                isMobile={isMobile}
                paymentPlanSchedule={paymentPlanSchedule}
                isPaymentPlan={isPaymentPlan}
                nextAutopayDate={nextAutopayDate}
                dueDate={dueDate}
                description={description}
                subDescription={subDescription}
                allowedPaymentInstruments={allowedPaymentInstruments}
                disableActions={disableActions}
              />
            )}
          </Box>
          {!isMobile && (
            <Box padding={"0"}>
              <ButtonWithAction
                isLoading={isLoading}
                action={disableActions ? noop : () => handleClick(obligations)}
                text={text}
                variant={variant}
                dataQa={text}
                disabled={disableActions}
              />
            </Box>
          )}
        </Cluster>
        {isMobile && (
          <Box padding="8px 0 0" width="100%">
            <ButtonWithAction
              isLoading={isLoading}
              action={disableActions ? noop : () => handleClick(obligations)}
              text={text}
              variant={variant}
              dataQa={text}
              extraStyles={isMobile && `flex-grow: 1; width: 100%; margin: 0;`}
              disabled={disableActions}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PaymentDetailsActions;
